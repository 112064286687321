import { useUserContext } from '../contexts/UserContext';
import Icon from '../components/ui/Icon';

export const NAV_ITEMS = [
    {
        title: 'Admin',
        roles: ['admin'],
        Icon: Icon.FileLock,
        children: [
            {
                title: 'Whiskeys',
                path: '/admin/whiskeys',
                Icon: Icon.GlassWater,
            },
            {
                title: 'Metrics',
                path: '/admin/metrics',
                Icon: Icon.SquareSigma,
            },
            {
                title: 'Badges',
                path: '/admin/badges',
                Icon: Icon.Award,
            },
            {
                title: 'Users',
                path: '/admin/users',
                Icon: Icon.Users,
            },
        ],
    },
];

const checkRolesRecursive = (item, userRoles) => {
    if (item.roles) {
        return (
            item.roles.some((role) => userRoles.includes(role)) &&
            (!item.children || item.children.every((child) => checkRolesRecursive(child, userRoles)))
        );
    }

    return true;
};

export const useNavItems = () => {
    const { user } = useUserContext();

    return NAV_ITEMS.filter((item) => {
        if (item.roles) {
            return checkRolesRecursive(item, user?.claims.roles ?? []);
        }

        return true;
    });
};
