import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../lib/firebase/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import * as React from 'react';
import { signOut } from '../lib/firebase/auth';
import { toast } from 'sonner';

const UserContext = createContext({
    user: null,
});

const UserProvider = ({ children }) => {
    const [user, setUser] = useState();
    const from = useLocation()?.state?.from;
    const navigate = useNavigate();
    const destination = useMemo(() => (from ? from : '/'), [from]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (!authUser) {
                setUser(null);
                return;
            }

            const u = await authUser?.getIdTokenResult(true);

            // eslint-disable-next-line
            u?.claims && setUser({ ...authUser, claims: { ...u.claims } });

            navigate(destination, { replace: true });
        });

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
    const context = useContext(UserContext);

    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
};

export { UserContext, UserProvider, useUserContext };
