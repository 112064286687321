import {
    GoogleAuthProvider,
    OAuthProvider,
    signInWithPopup,
    onAuthStateChanged as _onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut as _signOut,
} from 'firebase/auth';

import { auth } from './firebase';
import axios from 'axios';

export function onAuthStateChanged(cb) {
    return _onAuthStateChanged(auth, cb);
}

export function parseFirebaseAuthError(error) {
    switch (error.code) {
        case 'auth/user-not-found':
            return 'User not found';
        case 'auth/wrong-password':
            return 'Wrong password';
        case 'auth/too-many-requests':
            return 'Too many requests. Try again later';
        case 'auth/user-disabled':
            return 'User account is disabled';
        case 'auth/email-already-in-use':
            return 'Email already in use';
        case 'auth/weak-password':
            return 'Password is too weak';
        case 'auth/invalid-email':
            return 'Invalid email';
        default:
            return error.message;
    }
}

export async function signInWithEmail(email, password) {
    return await signInWithEmailAndPassword(auth, email, password);
}

export const PROVIDERS = {
    GOOGLE: 'google.com',
    APPLE: 'apple.com',
};

export async function signInWithCredential(provider, callback = () => {}) {
    switch (provider) {
        case PROVIDERS.GOOGLE:
            return await signInWithGoogle(callback);
        case PROVIDERS.APPLE:
            return await signInWithApple(callback);
        default:
            throw new Error('Invalid provider');
    }
}

export async function signInWithGoogle(callback = () => {}) {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(auth, provider).then(callback);
}

export async function signInWithApple(callback = () => {}) {
    const provider = new OAuthProvider('apple.com');
    return await signInWithPopup(auth, provider).then(callback);
}

export async function createUserWithEmail(email, password) {
    return await createUserWithEmailAndPassword(auth, email, password);
}

export async function signOut() {
    return await _signOut(auth);
}

export async function registerUser(userData, token) {
    const url = process.env.REACT_APP_DISTILLIFY_API_URL + `/user/register`;

    return await axios.post(url, userData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
